
import { _setIntlObject, addLocaleData, IntlProvider, intlShape } from 'umi/locale';

const InjectedWrapper = (() => {
  let sfc = (props, context) => {
    _setIntlObject(context.intl);
    return props.children;
  };
  sfc.contextTypes = {
    intl: intlShape,
  };
  return sfc;
})();


import 'moment/locale/en-nz';
import 'moment/locale/zh-cn';

const baseNavigator = true;
const useLocalStorage = true;

import { LocaleProvider } from '@whalecloud/fdx';
import moment from 'moment';
import 'moment/locale/en-nz';
let defaultFdx = require('@whalecloud/fdx/lib/locale-provider/en_US');
defaultFdx = defaultFdx.default || defaultFdx;

const localeInfo = {
  'en-US': {
    messages: {
      ...require('/home/ZCIPClient/workpath/48594/code/prc/code/prc_selfcare_prod/src/locales/en-US.js').default,...require('/home/ZCIPClient/workpath/48594/code/prc/code/prc_selfcare_prod/src/locales/en-US.json'),
    },
    locale: 'en-US',
    fdx: require('@whalecloud/fdx/lib/locale-provider/en_US'),
    data: require('react-intl/locale-data/en'),
    momentLocale: 'en-nz',
  },
  'zh-CN': {
    messages: {
      ...require('/home/ZCIPClient/workpath/48594/code/prc/code/prc_selfcare_prod/src/locales/zh-CN.js').default,...require('/home/ZCIPClient/workpath/48594/code/prc/code/prc_selfcare_prod/src/locales/zh-CN.json'),
    },
    locale: 'zh-CN',
    fdx: require('@whalecloud/fdx/lib/locale-provider/zh_CN'),
    data: require('react-intl/locale-data/zh'),
    momentLocale: 'zh-cn',
  },
  'zh': {
    messages: {
      ...require('/home/ZCIPClient/workpath/48594/code/prc/code/prc_selfcare_prod/src/locales/zh-CN.js').default,...require('/home/ZCIPClient/workpath/48594/code/prc/code/prc_selfcare_prod/src/locales/zh-CN.json'),
    },
    locale: 'zh',
    fdx: require('@whalecloud/fdx/lib/locale-provider/zh_CN'),
    data: require('react-intl/locale-data/zh'),
    momentLocale: 'zh-cn',
  },
  'en': {
    messages: {
      ...require('/home/ZCIPClient/workpath/48594/code/prc/code/prc_selfcare_prod/src/locales/en-US.js').default,...require('/home/ZCIPClient/workpath/48594/code/prc/code/prc_selfcare_prod/src/locales/en-US.json'),
    },
    locale: 'en',
    fdx: require('@whalecloud/fdx/lib/locale-provider/en_US'),
    data: require('react-intl/locale-data/en'),
    momentLocale: 'en-nz',
  },
};

let appLocale = {
  locale: 'en-US',
  messages: {},
  data: require('react-intl/locale-data/en'),
  momentLocale: 'en-nz',
};
if (useLocalStorage && localStorage.getItem('umi_locale') && localeInfo[localStorage.getItem('umi_locale')]) {
  appLocale = localeInfo[localStorage.getItem('umi_locale')];
} else if (localeInfo[navigator.language] && baseNavigator){
  appLocale = localeInfo[navigator.language];
} else {
  appLocale = localeInfo['en-US'] || appLocale;
}
window.g_lang = appLocale.locale;
appLocale.data && addLocaleData(appLocale.data);

export default function LocaleWrapper(props) {
  let ret = props.children;
  ret = (<IntlProvider locale={appLocale.locale} messages={appLocale.messages}>
    <InjectedWrapper>{ret}</InjectedWrapper>
  </IntlProvider>)
  ret = (<LocaleProvider locale={appLocale.fdx ? (appLocale.fdx.default || appLocale.fdx) : defaultFdx}>
    {ret}
  </LocaleProvider>);
  return ret;
}
